<template>
  <div class="warrty">
    <div class="tableList">
      <div class="exportData">
        <el-button class="textBtn" @click="AddRework" type="text">+新增</el-button>
      </div>
      <div class="table">
        <el-table :data="tableData" @selection-change="handleSelectionChange" stripe style="width: 100%">
          <el-table-column prop="code" label="采购品类代码" width="" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="name" label="采购品类名称" width="" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column label="操作" width="90px">
            <template #default="scope">
              <el-button class="textBtn" @click="eliminateDinlog(scope.$index, scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <dialog-box ref="prodiaData" componentName="ProcurementCategory" dialogWidth="650px" :dialogShow="ProdialogShow" @handleClose='handleClose' title="采购品类选择"
    :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addProdia', type:'primary', size:'mini'}]" @addProdia="prodiaBtn"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  name: 'SubModel',
  props: ['newSubModelData'],
  components: Component.components,
  data: function () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      AddReworkNum: 1,
      ProdialogShow: false,
      categoryData: {},
      categoryDataCopy: {},
      warrCheckData: [],
      tableData: [],
      newTableData: []
    }
  },
  mounted () {
    this.tableData = this.newSubModelData.list
  },
  created () {

  },
  methods: {
    // 采购品类选择弹框
    purchhandleShow (index, row) {
      this.categoryDataCopy = row
      this.ProdialogShow = true
    },

    // 新增采购品类弹框
    AddRework () {
      this.AddReworkNum = 2
      this.ProdialogShow = true
    },
    // 点击新增采购品类弹框里的确认按钮
    prodiaBtn () {
      this.categoryData = this.$refs.prodiaData.$refs.content.currentChangeData
      if (this.categoryData.value) {
        this.categoryDataCopy.cgModel = this.categoryData.value
        this.categoryDataCopy.cgModelName = this.categoryData.label
        if (this.AddReworkNum === 2) {
          this.newTableData.push({
            code: this.categoryData.value,
            name: this.categoryData.label
          })
          if (this.tableData) {
            this.tableData.unshift(...this.newTableData)
          } else {
            this.tableData = this.newTableData
          }
        }
        this.AddReworkNum = 1
        this.ProdialogShow = false
        this.$parent.$parent.subModelBtn()
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请点击选择采购品类'
        })
      }
    },

    // 获取参评品类定义选中的数据
    handleSelectionChange (val) {
      this.warrCheckData = val
    },

    // 删除
    handleRemove (index, row) {
      this.tableData.splice(index, 1)
    },
    // 删除弹框
    eliminateDinlog (index, row) {
      this.$confirm('删除后请点击确认进行保存', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleRemove(row)
      })
    },

    // 关闭弹窗
    handleClose () {
      this.ProdialogShow = false
    }
  }
}

</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.warrty{
  .tableList{
    padding: 0;
    :deep(.table .el-table td){
      padding: 2px 0;
    }
  }
}
.contractMenu{
 position: relative;
 .derivebtn{
  position: absolute;
  right: 35px;
  top: 8px;
  font-size: 16px;
 }
}

</style>
